<template>
  <div class="shopping-cart">
    <div
      class="shopping-cart__back"
      @click="back"
    >
      <a-icon
        type="left"
        class="shopping-cart__back__icon"
        :style="{ fontSize: '18px', color: '#8991A4' }"
      />
      <span>{{ $t('shoppingCart.back') }}</span>
    </div>
    <div class="shopping-cart__body">
      <div class="shopping-cart__body__product-box">
        <div
          class="shopping-cart__body__product-box__number"
        >{{ `${$t('shoppingCart.title')}(${cartNumber})` }}</div>
        <div class="shopping-cart__body__product-box__list">
          <cart-product-list @getSelectedProduct="getSelectedProduct"></cart-product-list>
        </div>
      </div>
      <div class="shopping-cart__body__payment-info">
        <div class="shopping-cart__body__payment-info__title">{{ $t('shoppingCart.total') }}</div>
        <div class="shopping-cart__body__payment-info__body">
          <div class="shopping-cart__body__payment-info__body__flex-layout">
            <span
              class="shopping-cart__body__payment-info__body__flex-layout__row-title"
            >{{ $t('shoppingCart.quantity') }}</span>
            <span
              class="shopping-cart__body__payment-info__body__flex-layout__product-selected-number"
            >{{ skuNumber | formatNumber }}</span>
          </div>
          <div class="shopping-cart__body__payment-info__body__flex-layout">
            <span
              class="shopping-cart__body__payment-info__body__flex-layout__row-title"
            >{{ $t('shoppingCart.total') }}</span>
            <span
              class="shopping-cart__body__payment-info__body__flex-layout__product-selected-money amount-total--global"
            >{{ totalMoney | formatMoney }}</span>
          </div>
          <op-button
            :disabled="skuNumber <= 0"
            class="shopping-cart__body__payment-info__checkout-button"
            @click="checkCartProductIsChange"
          >{{ $t('shoppingCart.checkout') }}</op-button>
        </div>
      </div>
    </div>
    <a-modal
      :closable="false"
      :mask-closable="false"
      :visible="visible"
    >
      <div>{{ $t('shoppingCart.changeTip') }}</div>
      <template slot="footer">
        <op-button @click="closTip">{{ $t('common.okay') }}</op-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import CartProductList from './components/cart-product-list.vue'
import { checkCartProductIsChange } from '@/api'
import { localStorage, sessionStorage, MallAnalytics } from '@/utils/index'
import { CART_PRODUCT_CHANGE } from '@/constants'

export default {
  components: { CartProductList },
  props: {},
  data() {
    return {
      selectedProductList: [],
      skuNumber: 0,
      totalMoney: 0,
      visible: false,
    }
  },

  computed: {
    cartNumber() {
      return this.$store.state.cartNumber
    },
  },

  methods: {
    getSelectedProduct(selectedProductInfo) {
      this.selectedProductList = selectedProductInfo.selectedProductList
      this.skuNumber = selectedProductInfo.skuNumber
      this.totalMoney = selectedProductInfo.totalMoney
    },
    sendBuryPointer() {
      const productList = this.selectedProductList.map(item => {
        return {
          spuId: item.goodsId,
          spuName: item.goodsName,
          skuId: item.skuId,
          salePrice: item.salePrice,
          skuNum: item.skuNum,
        }
      })
      MallAnalytics.mc('spmall_shoppingcart_processcheckout_click', {
        saleTotalPrice: this.totalMoney,
        productList,
        ...sessionStorage.get('channelCode', {}),
      })
    },
    closTip() {
      this.visible = false
      window.location.reload()
    },
    async checkCartProductIsChange() {
      this.sendBuryPointer()
      const params = this.selectedProductList.map(item => ({
        skuId: item.skuId,
        goodsId: item.goodsId,
        salePrice: item.salePrice,
        skuNum: item.skuNum,
        focusStock: item.focusStock,
        stock: item.skuStock,
      }))
      const { data } = await checkCartProductIsChange(params)
      if (data.change === CART_PRODUCT_CHANGE.CHANGE) {
        this.visible = true
        return
      }
      this.goPayment()
    },
    goPayment() {
      localStorage.set('accountProductList', this.selectedProductList)
      this.$router.push({
        path: '/checkout',
      })
    },

    back() {
      this.$router.back(-1)
    },
  },
}
</script>
<style lang="less" scoped>
.shopping-cart {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  background: #f9fafb;
  &__back {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 500;
    color: #8991a4;
    cursor: pointer;
    &__icon {
      margin-right: 8px;
    }
  }
  &__body {
    display: flex;
    &__product-box {
      flex: 1;
      margin-right: 16px;
      background: #fff;
      &__number {
        padding-left: 16px;
        line-height: 40px;
        color: var(--color-title);
        border-bottom: 1px solid #f4f6f8;
      }
    }
    &__payment-info {
      width: 230px;
      background: #fff;
      &__title {
        padding: 0 16px;
        line-height: 40px;
        color: var(--color-title);
        border-bottom: 1px solid #f4f6f8;
      }
      &__body {
        padding: 8px 16px 24px;
        &__flex-layout {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          &__row-title {
            flex: 1;
            padding-right: 4px;
            color: var(--color-sub-title);
          }
          &__product-selected-number {
            flex: 1;
            padding-left: 4px;
            text-align: right;
            vertical-align: middle;
          }
          &__product-selected-money {
            flex: 1;
            padding-left: 4px;
            text-align: right;
            vertical-align: middle;
          }
        }
      }
      &__checkout-button {
        width: 100%;
        margin-top: 32px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .shopping-cart {
    &__body {
      display: block;
      &__payment-info {
        width: 100%;
      }
      &__product-box {
        margin-right: 0;
      }
    }

    max-width: calc(100% - 80px);
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .shopping-cart {
    max-width: calc(100% - 32px);
    margin: 0 auto;
  }
}
</style>
