<template>
  <div class="cart-product-list">
    <a-row
      v-show="isShowCartList"
      class="cart-product-list__row cart-product-list__row--pc"
      type="flex"
      align="middle"
    >
      <a-col :style="{ width: '60px' }">
        <a-checkbox
          default-checked
          @change="changeAll"
        ></a-checkbox>
      </a-col>
      <a-col :style="{ width: '88px' }">{{ $t('shoppingCart.product') }}</a-col>
      <a-col flex="1"></a-col>
      <a-col flex="1">{{ $t('shoppingCart.unitPrice') }}</a-col>
      <a-col :style="{ width: '160px' }">{{ $t('shoppingCart.quantity') }}</a-col>
      <a-col flex="1">{{ $t('shoppingCart.total') }}</a-col>
    </a-row>
    <a-row
      v-for="(item, index) in cartProductList"
      v-show="isShowCartList"
      :key="index + 'pc'"
      class="cart-product-list__row cart-product-list__row--pc"
      type="flex"
      align="middle"
    >
      <a-col :style="{ width: '60px' }">
        <a-checkbox
          :disabled="item.valid === CART_PRODUCT_VALID.DISABLED"
          :checked="item.selected"
          @change="e => changeItem(e, item)"
        ></a-checkbox>
      </a-col>
      <a-col class="cart-product-list__row__image-box">
        <div
          v-if="item.valid === CART_PRODUCT_VALID.DISABLED"
          class="sold-out-tag"
        >
          <div>{{ $t('product.list.soldOut') }}</div>
        </div>
        <img
          v-lazy="item.imgUrl"
          class="cart-product-list__row__image-box__cover"
          @click="goProductDetail(index)"
        />
      </a-col>
      <a-col flex="1">
        <div class="cart-product-list__row__product-name">{{ item.goodsName }}</div>
        <div class="cart-product-list__row__product-sku">{{ getSkuInfo(index) }}</div>
      </a-col>
      <a-col
        class="cart-product-list__row__product-sale-price"
        flex="1"
      >{{ item.salePrice | formatMoney }}</a-col>
      <a-col :style="{ width: '160px' }">
        <div class="cart-product-list__row__cart-clo-flex">
          <quantity-input
            v-model="item.skuNum"
            :is-track-stock="item.focusStock"
            :list-index="index"
            :max-value="item.skuStock"
            @updateNumber="updateSoppingCart"
          ></quantity-input>
          <img
            v-compress="{
              src: 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/shopping-cart/delete.png',
            }"
            class="cart-product-list__row__cart-clo-flex__cart-delete-icon"
            @click="deleteCartProduct(index)"
          />
        </div>
        <div
          v-if="isShowStock(item)"
          class="cart-product-list__row__product-stock-tip"
        >
          <span>{{ $t('shoppingCart.stock') }}</span>
          <span class="cart-product-list__row__product-stock-tip__number">{{ item.skuStock }}</span>
        </div>
      </a-col>
      <a-col flex="1">{{ item.saleTotalPrice | formatMoney }}</a-col>
    </a-row>

    <a-row
      v-for="(item, index) in cartProductList"
      v-show="isShowCartList"
      :key="index + 'mobile'"
      class="cart-product-list__row cart-product-list__row--mobile"
      type="flex"
      align="middle"
    >
      <a-col :style="{ width: '44px' }">
        <a-checkbox
          :disabled="item.valid === CART_PRODUCT_VALID.DISABLED"
          :checked="item.selected"
          @change="e => changeItem(e, item)"
        ></a-checkbox>
      </a-col>
      <a-col class="cart-product-list__row__image-box">
        <div
          v-if="item.valid === CART_PRODUCT_VALID.DISABLED"
          class="sold-out-tag"
        >
          <div>{{ $t('product.list.soldOut') }}</div>
        </div>
        <img
          v-lazy="item.imgUrl"
          class="cart-product-list__row__image-box__cover"
          @click="goProductDetail(index)"
        />
      </a-col>
      <a-col flex="1">
        <div class="cart-product-list__row__product-name">{{ item.goodsName }}</div>
        <div class="cart-product-list__row__product-sale-price">{{ item.salePrice | formatMoney }}</div>
        <div class="cart-product-list__row__product-sku">{{ getSkuInfo(index) }}</div>
        <div class="cart-product-list__row__cart-clo-flex">
          <quantity-input
            v-model="item.skuNum"
            :is-track-stock="item.focusStock"
            :list-index="index"
            :max-value="item.skuStock"
            @updateNumber="updateSoppingCart"
          ></quantity-input>
          <img
            v-compress="{
              src: 'https://saas-fe-prod.pandova.cloud/static/pandova-mall/images/shopping-cart/delete.png',
            }"
            class="cart-delete-icon"
            @click="deleteCartProduct(index)"
          />
        </div>
        <div
          v-if="isShowStock(item)"
          class="cart-product-list__row__product-stock-tip"
        >
          <span>{{ $t('shoppingCart.stock') }}</span>
          <span class="cart-product-list__row__product-stock-tip__number">{{ item.skuStock }}</span>
        </div>
      </a-col>
    </a-row>
    <op-spin v-show="loading"></op-spin>
    <div
      v-show="!loading && cartProductList.length === 0"
      class="cart-no-data"
    >
      <a-empty />
    </div>
  </div>
</template>

<script>
import {
  getOnlineCartList,
  getLocalCartList,
  updateShoppingCartList,
  deleteShoppingCartProduct,
} from '@/api'
import { localStorage, generateUUID } from '@/utils/index'
import { IS_TRACK_STOCK, CART_PRODUCT_VALID } from '@/constants'
import { mapState } from 'vuex'
export default {
  props: {},
  data() {
    return {
      CART_PRODUCT_VALID,
      cartProductList: [],
      loading: false,
      allChecked: true,
    }
  },

  computed: {
    isShowCartList() {
      return !this.loading && this.cartProductList.length
    },
    ...mapState(['cartNumber', 'isAlreadyLogin']),
  },

  created() {
    this.isAlreadyLogin ? this.getOnlineCartList() : this.getLocalCartList()
  },

  methods: {
    generateUUID,
    isShowStock(productInfo) {
      return (
        productInfo.valid === CART_PRODUCT_VALID.VALID &&
        productInfo.focusStock === IS_TRACK_STOCK.TRACK_STOCK &&
        (productInfo.skuStock < productInfo.skuNum || productInfo.skuStock <= 5)
      )
    },
    getSkuInfo(index) {
      return (
        this.cartProductList[index].orderItemSpecsParamDTOList &&
        this.cartProductList[index].orderItemSpecsParamDTOList
          .map(item => item.specsValueName)
          .join(',')
      )
    },
    async getOnlineCartList() {
      try {
        this.loading = true
        const { data } = await getOnlineCartList()
        const cartProductList = data || []
        this.$store.dispatch('updateCartNumber')
        this.formatCartList(cartProductList, true)
        this.makeSelectedProduct()
      } finally {
        this.loading = false
      }
    },
    async getLocalCartList() {
      try {
        this.loading = true
        const cartListIdList = localStorage.get('shoppingCart', []).map(item => ({
          skuId: item.skuId,
          goodsId: item.goodsId,
          goodsName: item.goodsName,
          skuNum: item.skuNum,
          salePrice: item.salePrice,
          saleTotalPrice: item.saleTotalPrice,
          imgUrl: item.imgUrl,
          orderItemSpecsParamDTOList: item.orderItemSpecsParamDTOList,
        }))
        if (!cartListIdList || (cartListIdList && cartListIdList.length === 0)) {
          return
        }
        const { data } = await getLocalCartList(cartListIdList)
        const cartProductList = data || []
        localStorage.set('shoppingCart', cartProductList)
        this.$store.dispatch('updateCartNumber')
        this.formatCartList(cartProductList, true)
        this.makeSelectedProduct()
      } finally {
        this.loading = false
      }
    },
    formatCartList(cartProductList = [], isSelected = true) {
      this.cartProductList = cartProductList.map(item => ({
        ...item,
        selected: isSelected && item.valid === CART_PRODUCT_VALID.VALID,
      }))
    },
    changeAll(e) {
      this.formatCartList(this.cartProductList, e.target.checked)
      this.makeSelectedProduct(!e.target.checked)
    },

    changeItem(e, item) {
      item.selected = !item.selected
      this.makeSelectedProduct()
    },

    updateSoppingCart({ number, index }) {
      if (number === 0) {
        this.deleteCartProduct(index)
        return
      }
      this.isAlreadyLogin
        ? this.updateOnlineCart({ number, index })
        : this.updateLocalCart({ number, index })
    },
    async updateOnlineCart({ number, index }) {
      this.cartProductList[index].saleTotalPrice = number * this.cartProductList[index].salePrice
      const params = {
        id: this.cartProductList[index].id,
        skuId: this.cartProductList[index].skuId,
        skuNum: number,
        salePrice: this.cartProductList[index].salePrice,
      }
      this.cartProductList[index].saleTotalPrice = number * this.cartProductList[index].salePrice
      await updateShoppingCartList(params)
      this.makeSelectedProduct()
      this.$store.dispatch('updateCartNumber')
    },
    updateLocalCart({ number, index }) {
      this.cartProductList[index].saleTotalPrice = number * this.cartProductList[index].salePrice
      localStorage.set('shoppingCart', this.cartProductList)
      this.makeSelectedProduct()
      this.$store.dispatch('updateCartNumber')
    },
    makeSelectedProduct(cancelCheckAll = false) {
      const selectedProductList = []
      let skuNumber = 0
      let totalMoney = 0
      if (cancelCheckAll) {
        this.$emit('getSelectedProduct', { selectedProductList, skuNumber, totalMoney })
        return
      }
      this.cartProductList.forEach((item, index) => {
        if (item.selected && item.valid === CART_PRODUCT_VALID.VALID) {
          selectedProductList.push(item)
          skuNumber += item.skuNum
          totalMoney += item.skuNum * item.salePrice
        }
      })

      this.$emit('getSelectedProduct', { selectedProductList, skuNumber, totalMoney })
    },
    deleteCartProduct(index) {
      this.isAlreadyLogin ? this.deleteOnlineCartProduct(index) : this.deleteLocalCartProduct(index)
    },
    async deleteOnlineCartProduct(index) {
      try {
        this.loading = true
        await deleteShoppingCartProduct({ idList: [this.cartProductList[index].id] })
        this.cartProductList.splice(index, 1)
        this.$store.dispatch('updateCartNumber')
        this.makeSelectedProduct()
      } finally {
        this.loading = false
      }
    },
    deleteLocalCartProduct(index) {
      this.cartProductList.splice(index, 1)
      localStorage.set('shoppingCart', this.cartProductList)
      this.$store.dispatch('updateCartNumber')
      this.makeSelectedProduct()
    },
    goProductDetail(index) {
      this.$router.push({
        path: `/products/${this.cartProductList[index].goodsId}`,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.cart-product-list {
  padding: 8px 16px;
  word-break: break-all;
  &__row {
    position: relative;
    &__mask {
      position: absolute;
    }

    &__image-box {
      position: relative;
      width: 100px;
      .sold-out-tag {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        font-size: 15px;
        color: #fff;
        text-align: center;
        word-break: break-word;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      &__cover {
        display: block;
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
    }

    padding: 8px 0;
    &__check-icon {
      display: block;
      width: 20px;
      height: 20px;
      object-fit: cover;
    }

    &__cart-clo-flex {
      display: flex;
      &__cart-delete-icon {
        cursor: pointer;
      }
    }
    &__product-sale-price {
      color: var(--color-product-sale-price);
    }
    &__product-stock-tip {
      line-height: 28px;
      color: #e34d59;
      &__number {
        margin-left: 4px;
      }
    }
    /deep/ .ant-col {
      padding: 4px;
    }
  }
  &__row--mobile {
    display: none;
  }
}
.cart-no-data {
  height: 400px;
  padding-top: 100px;
  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
  }
  .no-data-text {
    margin-top: 32px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .cart-product-list {
    &__row--pc {
      display: none;
    }
    &__row--mobile {
      display: flex;
    }
  }
}
</style>
